import axios from 'axios';

axios.defaults.headers.common['Content-Type'] = 'application/json';

axios.defaults.timeout = 100000;

const signupClient = axios.create({ baseURL: process.env.VUE_APP_REGISTRATION_URL });

signupClient.interceptors.request.use(
  (cfg) => cfg,
  (error) => Promise.reject(error)
  ,
);

const successInterceptor = ({ data }) => {
  // crutch for old (Vertx) API
  if (Object.prototype.hasOwnProperty.call(data, '$items')) return data.$items;
  if (Object.prototype.hasOwnProperty.call(data, '$value')) return data.$value;

  return data;
};

const errorInterceptor = (error) => {
  const { response } = error;
  return Promise.reject(response);
};

// Add a response interceptor
signupClient.interceptors.response.use(
  successInterceptor,
  errorInterceptor
  ,
);

export default signupClient;
