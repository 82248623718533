<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',

};
</script>

<style lang="scss">

#app {
  background: #735FF5;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
