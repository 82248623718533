import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '../plugins/i18n';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: i18n.t('title'),
    },
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/*',
    redirect: '/',
    meta: {
      title: i18n.t('title'),
    },
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.beforeEach(({ meta }, from, next) => {
  document.title = meta.title;
  next();
});

export default router;
