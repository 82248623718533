import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function getBrowserLocale() {
  const langsPool = ['ru', 'ua', 'kk', 'be'];

  return langsPool.some((lang) => lang === navigator.language.split('-')[0]) ? 'ru' : 'en';
}

const defaultLocale = process.env.VUE_APP_I18N_LOCALE;
const browserLocale = getBrowserLocale();

function loadLocaleMessages() {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export default new VueI18n({
  locale: localStorage.getItem('locale') || browserLocale || defaultLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
});
